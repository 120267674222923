import { Body, Button, useModal } from '@sumup/circuit-ui';
import Cookies from 'js-cookie';
import { useRouter } from 'next/router';
import React, { useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/image';
import { OptimizelyContext } from '@optimizely/react-sdk';

import {
  PromoModalButtons,
  PromoModalContainer,
  PromoModalContent,
  PromoModalHeadline,
} from '../styled';

import { SearchEventEntryPoint } from 'types/common';
import * as Analytics from 'services/analytics';

const AI_PROMO_MODAL_COOKIE = 'ai_promo_modal_shown';

const PromoModal = () => {
  const { setModal, removeModal } = useModal();
  const { t } = useTranslation();
  const { optimizely } = useContext(OptimizelyContext);
  const router = useRouter();

  useEffect(() => {
    Analytics.AnalyticsEvents.sendEvent({
      'event': 'interaction',
      'action': 'ai_search_modal_rendered',
      'target': 'customer_support_centre',
      'target-properties': '',
      'app_screen_name': '',
      'widget_name': '',
      'locale': router.locale,
      'business_feature': 'search',
    } as SearchEventEntryPoint);
    Analytics.AnalyticsEvents.sendModalRendered({ locale: router.locale });
  }, [router.locale]);

  useEffect(() => {
    if (!Cookies.get(AI_PROMO_MODAL_COOKIE)) {
      setModal({
        onClose: () => {
          Cookies.set(AI_PROMO_MODAL_COOKIE, 'true', {
            expires: 365,
          });
          Analytics.AnalyticsEvents.sendEvent({
            'event': 'interaction',
            'action': 'ai_search_modal_dismissed',
            'target': 'customer_support_centre',
            'target-properties': '',
            'app_screen_name': '',
            'widget_name': '',
            'locale': router.locale,
            'business_feature': 'search',
          } as SearchEventEntryPoint);
          Analytics.AnalyticsEvents.sendButtonClick({
            buttonDescription: 'modal_dismissal',
            locale: router.locale,
            modalName: 'ai_search',
          });
        },
        closeButtonLabel: 'close',
        children: (
          <PromoModalContainer>
            <div css={{ width: '100%' }}>
              <Image
                alt="promo"
                src={'/aipromo.gif'}
                width="680"
                height="300"
                layout="responsive"
              />
            </div>
            <PromoModalContent>
              <PromoModalHeadline as="h1" size="two">
                {t('ai_search.promo.title')}
              </PromoModalHeadline>
              <Body css={{ textAlign: 'center' }}>
                {t('ai_search.promo.description')}
              </Body>
              <PromoModalButtons>
                <Button
                  onClick={() => {
                    router
                      .push('/ai-search')
                      .then(() => {})
                      .catch(() => {});
                    Analytics.AnalyticsEvents.sendEvent({
                      'event': 'interaction',
                      'action': 'open_ai_search_clicked',
                      'target': 'customer_support_centre',
                      'target-properties': '',
                      'app_screen_name': '',
                      'widget_name': '',
                      'locale': router.locale,
                      'business_feature': 'search',
                      'internal_origin': 'modal',
                    } as SearchEventEntryPoint);
                    Analytics.AnalyticsEvents.sendButtonClick({
                      buttonDescription: 'open_ai_search_opening',
                      locale: router.locale,
                      modalName: 'ai_search',
                    });
                    optimizely?.track(
                      'search_initiated',
                      optimizely?.user?.id,
                      {},
                    );
                    removeModal();
                  }}
                  variant="primary"
                >
                  {t('ai_search.promo.cta_main')}
                </Button>
                <Button
                  onClick={() => {
                    removeModal();
                  }}
                >
                  {t('ai_search.promo.close')}
                </Button>
              </PromoModalButtons>
            </PromoModalContent>
          </PromoModalContainer>
        ),
      });
    }
  }, []);

  return <></>;
};

export default PromoModal;
